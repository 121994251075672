import { ValidationErrorMessages, ValidationMethods } from "@repo/validation";
import { createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";

type InputElements = HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement;

export interface FormValidationData {
  touched: {
    [key: string]: boolean;
  };
  touchedTimers: {
    [key: string]: ReturnType<typeof setTimeout>;
  };
  elements: {
    [key: string]: InputElements;
  };
  values: {
    [key: string]: string;
  };
  validation: {
    [key: string]: Array<keyof typeof ValidationMethods>;
  };
  messages: {
    [key: string]: string;
  };
}

export const createValidationData: () => FormValidationData = () => ({
  touched: {},
  touchedTimers: {},
  elements: {},
  values: {},
  validation: {},
  messages: {},
});

/**
 * Form validation hook.
 *
 * @example
 * ```ts
 * const { validates, data, eventHandler } = useFormValidation(form);
 * form.addEventListener("input", eventHandler);
 * form.addEventListener("change", eventHandler);
 * ```
 */
export const useFormValidation = (form: HTMLFormElement) => {
  let submitButton: HTMLButtonElement | null = null;

  /**
   * Extracts fields from the form element.
   */
  const fields = Array.from(form.elements).filter((element): element is InputElements => {
    if (element instanceof HTMLInputElement) {
      return true;
    }
    if (element instanceof HTMLTextAreaElement) {
      return true;
    }
    if (element instanceof HTMLButtonElement && element.type === "submit") {
      submitButton = element;
    }
    return false;
  });

  /**
   * Creates the initial data object for the form.
   */
  const createData = (fields: InputElements[]) => {
    const result: FormValidationData = createValidationData();
    fields.map((field) => {
      if (field) {
        result.touched[field.name] = field.type === "hidden";
        result.elements[field.name] = field;
        result.values[field.name] = field.value || "";
        result.validation[field.name] = [];
        const validation = field.getAttribute("data-validation");
        if (validation) {
          const entry = result.validation[field.name];
          if (entry) {
            result.validation[field.name] = validation.split(",") as Array<keyof typeof ValidationMethods>;
          }
        }
      }
    });
    return result;
  };
  const [data, setData] = createStore<FormValidationData>(createData(fields));
  const createValues = (fields: InputElements[]) => {
    const result: { [key: string]: string } = {};
    fields.map((field) => {
      result[field.name] = field.value;
    });
    return result;
  };

  /**
   * Iterates across the form fields and validates them against the validation methods set within `data-validation`.
   */
  const validate = () => {
    let result = true;
    fields.forEach((field) => {
      if (result === true) {
        let valid = true;
        //  Ensure we have a validation object for the field.
        const validation = data.validation[field.name];
        if (validation) {
          //  Iterate across our validation methods for this field.
          let count = validation.length;
          while (count) {
            count = count - 1;
            const current = validation[count];
            //  Continue if the validation field is still valid & the field remains untouched.
            // if (current && valid && data.touched[field.name] === true) {
            if (current && valid) {
              const method = ValidationMethods[current];
              if (method) {
                //  If the validation fails, set the result to invalid and set the error message.
                if (!method(field.value)) {
                  valid = false;
                  setData(
                    "messages",
                    field.name,
                    ValidationErrorMessages[current as keyof typeof ValidationErrorMessages],
                  );
                }
                //  Otherwise clear any existing messages.
                else {
                  setData("messages", field.name, "");
                }
              }
            }
          }
        }

        //  If any validation methods have failed, set the field to invalid.
        // if (!valid && data.touched[field.name] === true) {
        if (!valid) {
          result = false;
          field.setAttribute("aria-invalid", "true");
        } else {
          field.removeAttribute("aria-invalid");
        }
      }
    });
    return result;
  };

  /**
   * Signal to indicate the current form state.
   */
  const [validates, setValidates] = createSignal(validate());
  createEffect(() => {
    if (submitButton) {
      if (validates()) {
        submitButton.removeAttribute("disabled");
      } else {
        submitButton.setAttribute("disabled", "true");
      }
    }
  });

  /**
   * We use setTimeout to ensure that the form is not validated before the user has had ample opportunity to complete their entry.
   */
  const touchTimer = (name: string) => {
    return setTimeout(() => {
      setData("touched", name, true);
      setValidates(validate());
    }, 1000);
  };

  /**
   * Event handler for form validation.
   *
   * Should be bound to `input` and `change` events of the form element.
   */
  const eventHandler = (event: Event) => {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      const values = createValues(fields);
      if (data.touched[target.name] === false) {
        if (data.touchedTimers[target.name]) {
          clearTimeout(data.touchedTimers[target.name]);
        }
        setData("touchedTimers", target.name, touchTimer(target.name));
      }
      setData("values", values);
      setValidates(validate());
    }
  };

  return { validates, data, eventHandler };
};
