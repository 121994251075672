import type { ParentProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./FloatingPromptBarContainer.module.css";

interface Props extends ParentProps {
  id?: string;
  modifier?: "fixed" | "left";
}

export const FloatingPromptBarContainer = (props: Props) => (
  <div
    id={props.id}
    class={classNames(
      styles["floating-prompt-bar-container"],
      props.modifier === "fixed" ? styles["floating-prompt-bar-container--fixed"] : "",
      props.modifier === "left" ? styles["floating-prompt-bar-container--left"] : "",
    )}
  >
    {props.children}
  </div>
);
