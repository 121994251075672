import { type Component, For, Show, Suspense } from "solid-js";
import { CollectionKind, type CollectionSnapshot } from "~/domains/collections/collections.types";
import { SidebarCollection } from "~/domains/collections/components/SideBarCollection";
import { useWire } from "~/wire";
import { Spinner } from "../Loaders";
import { SideBarSectionAccordion } from "./SideBarSectionAccordion";

enum VisibleCollections {
  All = 0,
  BothSharedAndPersonal = 1,
  OnlyPersonal = 2,
}

export const SidebarCollections: Component = () => {
  const wire = useWire();
  const collections = wire.services.collections;
  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;

  const roots = () =>
    !collections.getRootsLoaded()
      ? []
      : ([CollectionKind.Favorites, CollectionKind.Org, CollectionKind.Personal].map(
          collections.getRootCollectionByKind,
        ) as CollectionSnapshot[]);

  const showFavorites = () => wire.services.collections.getChildrenIds(roots()[0]?.id || "").length !== 0;

  return (
    <Suspense fallback={<Spinner class="size-6" />}>
      <Show when={collections.getRootsLoaded() && tree()} fallback={<Spinner class="size-6" />}>
        <Show when={showFavorites()}>
          <SideBarSectionAccordion label="Starred" initiallyOpen depth={0}>
            <SidebarCollection
              onCollectionCreated={refetchTree}
              root
              collectionId={roots()[0]?.id || ""}
              depth={0}
              refreshTree={refetchTree}
            />
          </SideBarSectionAccordion>
        </Show>

        <SideBarSectionAccordion label="Collections" initiallyOpen depth={0}>
          <For each={roots().slice(VisibleCollections.OnlyPersonal)}>
            {(root) => (
              <li>
                <SidebarCollection
                  onCollectionCreated={refetchTree}
                  root
                  collectionId={root.id}
                  depth={0}
                  refreshTree={refetchTree}
                />
              </li>
            )}
          </For>
        </SideBarSectionAccordion>
      </Show>
    </Suspense>
  );
};
