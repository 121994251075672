import { z } from "zod";

const methods = () =>
  ({
    required: (value: string) => !!value,
    collectionName: (value: string) => value.length >= 2 && value.length <= 128,
    collectionDescription: (value: string) => value.length <= 1024,
    email: (value: string) => {
      const schema = z.string().email();
      return schema.safeParse(value).success;
    },
  }) as const;

export const ValidationErrorMessages = {
  required: "This field is required.",
  collectionName:
    "Your collection name should be at least 3 characters long and contain only letters, numbers, and spaces.",
  email: "Please enter a valid email address.",
} as const;

/**
 * Validation methods
 */
export const ValidationMethods = methods();
