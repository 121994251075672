import { BsQuestion } from "solid-icons/bs";
import { TbRocket } from "solid-icons/tb";
import { type Component, Show, createSignal, createUniqueId } from "solid-js";
import { useUIState } from "~/ui/UIState";
import { IconCTA } from "../CTA/IconCTA";
import { SecondaryCTA } from "../CTA/SecondaryCTA";
import { HelpContextMenu } from "../_context-menu/HelpContextMenu";
import styles from "./CollectionsTop.module.css";
import { UserAvatarMenu } from "./UserAvatarMenu";

export const CollectionsTop: Component<{ showGettingStarted?: boolean }> = (props) => {
  const ui = useUIState();
  const [, setGettingStartedScreenManuallyHidden] = ui.gettingStartedScreenManuallyHidden;
  const [helpRef, setHelpRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const helpId = `help-menu-${createUniqueId()}`;
  return (
    <>
      <div class={styles["collections-top"]}>
        <div class={styles["collections-top__left"]}>
          <Show when={props.showGettingStarted}>
            <SecondaryCTA
              data-test-id="copy-blockquote-text"
              label="Get started with Collections!"
              accessibleSuffix="the source snippet."
              icon={TbRocket}
              onClick={() => {
                setGettingStartedScreenManuallyHidden(false);
              }}
            />
          </Show>
          {/* <LargeSearchBar placeholder="Search this collection" label="Search this collection" /> */}
        </div>
        <div class={styles["collections-top__right"]}>
          <IconCTA
            data-test-id="view-documentation"
            accessibleLabel="View documentation"
            icon={BsQuestion}
            modifiers={["small"]}
            ref={(ref: HTMLElement) => {
              setHelpRef(ref);
            }}
          />
          <HelpContextMenu bound={helpRef()} id={helpId} placement="bottom-start" />
          <UserAvatarMenu />
        </div>
      </div>
    </>
  );
};
