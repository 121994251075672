import { BsPersonFillLock } from "solid-icons/bs";
import { TbKeyframes } from "solid-icons/tb";
import { Match, Suspense, Switch, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import { PrimaryCTA } from "~/components/CTA/PrimaryCTA";
import { LargeInputText } from "~/components/SideBar/_fields/LargeInputText";
import { TextArea } from "~/components/SideBar/_fields/TextArea";
import type { CollectionsModalProps } from "~/components/_containers/CollectionsModal";
import { StIcon } from "~/components/icons";
import { useUIState } from "~/ui/UIState";
import { type FormValidationData, useFormValidation } from "~/ui/useFormValidation";
import styles from "./CreateCollectionModal.module.css";

interface Props extends CollectionsModalProps {
  onCreate?: (name: string, description?: string) => void;
  parentLabel: string;
}

export const CreateCollectionModal = (props: Props) => {
  const [modal] = useUIState().modal;
  const [form, setForm] = createSignal((<></>) as HTMLFormElement);
  const [focus, setFocus] = createSignal(false);
  const [canSubmit, setCanSubmit] = createSignal(false);
  const [data, setData] = createSignal<FormValidationData>();
  let submitting = false;
  const onSubmit = (event: SubmitEvent) => {
    if (event.target instanceof HTMLFormElement && !submitting) {
      submitting = true;

      const d = data();
      const name = d?.values.name;
      const description = d?.values.description;
      props.onCreate?.(name || "", description);
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };

  createEffect(() => {
    if (modal() === props.id) {
      setFocus(true);
    }
    if (modal() === "") {
      setFocus(false);
    }
  });

  onMount(() => {
    submitting = false;
    if (form()) {
      const { validates, data, eventHandler } = useFormValidation(form());
      form().addEventListener("input", eventHandler);
      form().addEventListener("change", eventHandler);
      createEffect(() => {
        setCanSubmit(validates());
        setData(data);
      });

      onCleanup(() => {
        form().removeEventListener("input", eventHandler);
        form().removeEventListener("change", eventHandler);
      });
    }
  });

  return (
    <Suspense>
      <form ref={(ref) => setForm(ref)} class={styles["create-collection-modal"]} onSubmit={onSubmit}>
        <p class={styles["create-collection-modal__title"]}>
          <span class={styles["create-collection-modal__title-icon"]}>
            <Switch fallback={<StIcon icon={TbKeyframes} size="1.25rem" />}>
              <Match when={props.parentLabel === "Personal Collection"}>
                <StIcon icon={BsPersonFillLock} size="1.25rem" />
              </Match>
            </Switch>
          </span>
          <span class={styles["create-collection-modal__title-text"]}>{props.parentLabel}</span>
        </p>
        <LargeInputText
          required={true}
          focus={focus}
          placeholder="Create new collection"
          label="Name your new collection"
          name="name"
          validation={["required", "collectionName"]}
          value=""
          class={styles["create-collection-modal__input"]}
          errorMessage={data()?.messages.name}
          modifier="overlay"
        />
        <TextArea
          placeholder="Add a description (optional)"
          label="Add more details for your collection"
          validation={["collectionDescription"]}
          name="description"
        />
        <div class={styles["create-collection-modal__buttons"]}>
          {/* <p class={styles.createCollectionModalLabel}>Get started with</p> */}
          <div aria-hidden class={styles["create-collection-modal__buttons-left"]}>
            {/* <SecondaryCTA
              data-test-id="create-collections-modal-upload"
              icon={TbShare2}
              label="Upload documents"
              accessibleSuffix="to your collection"
              class={styles.createCollectionModalButton}
            />
            <SecondaryCTA
              data-test-id="create-collections-modal-chat"
              icon={TbMessage2}
              label="Add Smartchats&trade;"
              accessibleSuffix="to your collection"
            /> */}
          </div>
          <div class={styles["create-collection-modal__buttons-right"]}>
            <PrimaryCTA
              data-test-id="create-collections-modal-create"
              accessibleSuffix=""
              label="Create Collection"
              type="submit"
              rounded
              inactive={!canSubmit()}
            />
          </div>
        </div>
      </form>
    </Suspense>
  );
};
