import { type ParentProps, createEffect } from "solid-js";
import { classNames } from "~/lib/classNames";
import { useUIState } from "~/ui/UIState";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import styles from "./LeftDrawer.module.css";

interface Props extends ParentProps {
  class?: string;
}

export const LeftDrawer = (props: Props) => {
  const state = useUIState();
  const [open, setOpen] = state.leftDrawer;
  const [isDesktop] = state.isDesktop;
  createEffect(() => {
    if (isDesktop()) {
      setOpen(false);
    }
  });

  return (
    <div
      id={GLOBAL_ELEMENT_IDS.LeftDrawer}
      class={classNames(styles["left-drawer"], open() ? styles["left-drawer--open"] : "", props.class)}
    >
      {props.children}
    </div>
  );
};
