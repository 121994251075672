import { useNavigate } from "@solidjs/router";
import type { Accessor } from "solid-js";
import { CollectionKind } from "~/domains/collections/collections.types";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";

export const useCreateNewSmartchatInCollection = (collectionId: Accessor<string | undefined>) => {
  const wire = useWire();
  const navigate = useNavigate();
  const col = () =>
    wire.services.collections.getCollection(collectionId() || "") || wire.services.collections.getPersonalRoot();

  const onCreateSmartchat = async () => {
    const c = col();
    if (!c) throw Error("No collection");

    const res = await wire.services.threads.createThread({
      label: "New",
      collectionId: c.id,
      organizationId: c.organizationId,
      tenantId: c.tenantId,
    });

    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());
    navigate(urls.threadV2(res.data.threadId));
    return res;
  };

  return { onCreateSmartchat };
};
