export const AVATAR_VARIANT_CLASSES = [
  "avatar-blue",
  "avatar-red",
  "avatar-green",
  "avatar-orange",
  "avatar-brown",
  "avatar-grey",
  "avatar-teal",
  "avatar-purple",
  "avatar-marine",
  "avatar-burgundy",
] as const;
export type AvatarVariant = (typeof AVATAR_VARIANT_CLASSES)[number];

/**
 * **assignAvatarColor**
 *
 * Using an input string, such as a username, generate a color variant for an avatar.
 */
export const assignAvatarColor = (str: string): string => {
  if (!str) {
    return AVATAR_VARIANT_CLASSES[0];
  }

  const count = AVATAR_VARIANT_CLASSES.length;

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash | 0;
  }

  // Map the hashed value to the range 0-100
  const rangeValue = Math.abs(hash % 101);

  // Map the range value to the index of the AVATAR_VARIANT_CLASSES array
  const index = Math.min(Math.floor(rangeValue / (100 / count)), count - 1);

  return AVATAR_VARIANT_CLASSES[index] || AVATAR_VARIANT_CLASSES[0];
};
