import { Match, Switch, splitProps } from "solid-js";
import { StIcon, type StIconComponent } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./IconCTA.module.css";
import type { CTAProps } from "./types";

type Modifiers = "surface" | "small"; // "large" | "background"
interface Props extends CTAProps {
  accessibleLabel: string;
  icon: StIconComponent;
  modifiers?: Modifiers[];
}

export const IconCTA = (props: Props) => {
  const [local, rest] = splitProps(props, ["accessibleLabel", "icon", "modifiers"]);
  return (
    <button
      {...rest}
      type="button"
      class={classNames(
        styles["icon-cta"],
        props.class,
        local.modifiers ? local.modifiers.map((modifier) => styles[`icon-cta--${modifier}`]).join(" ") : "",
      )}
      title={local.accessibleLabel}
      tabIndex={props.tabIndex ?? 0}
    >
      <Switch
        fallback={
          <>
            <StIcon icon={local.icon} class={styles["icon-cta__icon"]} size="1.25rem" />
            <span class="screen-reader">{local.accessibleLabel}</span>
          </>
        }
      >
        <Match when={props.modifiers?.includes("small")}>
          <span class={styles["icon-cta__inner"]}>
            <StIcon icon={local.icon} class={styles["icon-cta__icon"]} size="1.25rem" />
            <span class="screen-reader">{local.accessibleLabel}</span>
          </span>
        </Match>
      </Switch>
    </button>
  );
};
