import { classNames } from "~/lib/classNames";
import styles from "./StorytellLogo.module.css";

interface Props {
  spacing?: boolean;
}

export const StorytellLogo = (props: Props) => (
  <a
    class={classNames(styles["storytell-logo"], props.spacing ? styles["storytell-logo--spacing"] : "")}
    href="/"
    target="_self"
  >
    <span class="screen-reader">Go to homepage</span>
    <span class={styles["storytell-logo__image"]} role="presentation" />
  </a>
);
