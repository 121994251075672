import type { IconTypes } from "solid-icons";
import { Show, splitProps } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./PrimaryCTA.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  icon?: IconTypes;
  inactive?: boolean;
  rounded?: boolean;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
}
type Props = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;

export const PrimaryCTA = (props: Props) => {
  const [local, rest] = splitProps(props, [
    "label",
    "accessiblePrefix",
    "accessibleSuffix",
    "icon",
    "class",
    "rounded",
    "inactive",
  ]);
  return (
    <button
      {...rest}
      type={props.type ?? "button"}
      class={classNames(
        styles["primary-cta"],
        local.rounded ? styles["primary-cta--rounded"] : "",
        local.inactive ? styles["primary-cta--inactive"] : "",
        local.class,
      )}
      tabIndex={0}
      disabled={local.inactive}
    >
      <Show when={local.icon}>
        {/* biome-ignore lint/style/noNonNullAssertion: Not correctly identifying <Show> condition */}
        <StIcon icon={local.icon!} class={styles["primary-cta__icon"]} size="1.25rem" />
      </Show>
      <Show when={local.accessiblePrefix}>
        <span class="screen-reader">{local.accessiblePrefix}</span>
      </Show>
      <span class={styles["primary-cta__label"]}>{local.label}</span>
      <Show when={local.accessibleSuffix}>
        <span class="screen-reader">{local.accessibleSuffix}</span>
      </Show>
    </button>
  );
};
