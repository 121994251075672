import { TbMessage } from "solid-icons/tb";
import { Show } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./ListedChat.module.css";

interface Props {
  label: string;
  collection?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const ListedChat = (props: Props) => {
  return (
    <button
      type="button"
      on:click={props.onClick}
      class={classNames(styles["listed-chat"], props.selected ? styles["listed-chat--selected"] : "")}
      tabIndex={0}
    >
      <TbMessage class={styles["listed-chat__icon"]} size={"1.5rem"} />
      <span class={styles["listed-chat__content"]}>
        <span class={styles["listed-chat__label"]}>{props.label}</span>
        <Show when={props.collection}>
          <span class={styles["listed-chat__sub"]}>{props.collection}</span>
        </Show>
      </span>
    </button>
  );
};
